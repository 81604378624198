<template>
  <h2>
    Persönliche Informationen<br />
    <h6>
      (dev only:
      <button @click.prevent="generateMockUp" class="btn btn-success p-2 mx-1">
        <font-awesome-icon icon="user-plus" class="px-0" />
      </button>
      <button @click.prevent="emptyForm" class="btn btn-danger p-2 mx-1">
        <font-awesome-icon icon="user-minus" class="px-0" /></button
      >)
    </h6>
  </h2>
  <form
    class="needs-validation"
    @submit.prevent="validateConsentData"
    novalidate
  >
    <div class="form-floating mb-4">
      <input
        type="text"
        class="form-control"
        :class="[
          firstNameError === 1 ? 'is-invalid' : '',
          firstNameError === 2 ? 'is-valid' : '',
        ]"
        id="floatingInput"
        placeholder="Franz"
        autocomplete="given-name"
        v-model="store.subject.firstName"
      />
      <label for="floatingInput">Vorname</label>
      <div class="invalid-feedback">
        Bitte geben Sie einen gültigen Vornamen ein.
      </div>
    </div>
    <div class="form-floating mb-4">
      <input
        type="text"
        class="form-control"
        :class="[
          lastNameError === 1 ? 'is-invalid' : '',
          lastNameError === 2 ? 'is-valid' : '',
        ]"
        id="floatingInput"
        autocomplete="family-name"
        placeholder="Musterfrau"
        v-model="store.subject.lastName"
      />
      <label for="floatingInput">Nachname</label>
      <div class="invalid-feedback">
        Bitte geben Sie einen gültigen Nachnamen ein.
      </div>
    </div>

    <div class="form-floating mb-4">
      <input
        type="date"
        :class="[
          store.temp.ageValidationError === 1 ? 'is-invalid' : '',
          store.temp.ageValidationError === 2 ? 'is-valid' : '',
        ]"
        class="form-control"
        id="floatingInput"
        placeholder="01.01.1970"
        v-model="store.subject.dateOfBirth"
      />
      <label for="floatingInput">Geburtsdatum</label>
      <div class="invalid-feedback">
        Ihr angegebenes Geburtsdatum passt nicht zu Ihrem Alter.
      </div>
    </div>

    <div class="form-floating mb-4">
      <input
        type="number"
        class="form-control"
        id="floatingInput"
        placeholder="123412345678"
        :class="[
          socialSecurityError == 1 ? 'is-valid' : '',
          socialSecurityError > 1 ? 'is-invalid' : '',
        ]"
        v-model="store.subject.socialSecurity"
      />
      <label for="floatingInput">Sozialversicherungsnummer</label>
      <div class="invalid-feedback" v-if="socialSecurityError === 2">
        Bitte geben Sie eine 10-stellige Sozialversicherungsnummer an.
      </div>
      <div class="invalid-feedback" v-if="socialSecurityError === 3">
        Geburtsdatum und Sozialversicherungsnr. passen nicht zusammen.
      </div>
      <div class="invalid-feedback" v-if="socialSecurityError === 4">
        Bitte geben Sie eine gültige Sozialversicherungsnummer an (nur Zahlen).
      </div>
    </div>
    <div class="text-center">
      <label class="form-label"><h5>Geschlecht:</h5></label>
    </div>
    <div class="btn-group btn-group-registration w-100 mb-4" role="group">
      <input
        type="radio"
        class="btn-check"
        id="male"
        value="male"
        v-model="store.subject.sex"
      />
      <label class="btn btn-outline-primary" for="male">Männlich</label>
      <input
        type="radio"
        class="btn-check"
        id="female"
        value="female"
        v-model="store.subject.sex"
      />
      <label class="btn btn-outline-primary" for="female">Weiblich</label>
      <input
        type="radio"
        class="btn-check"
        id="other"
        value="other"
        v-model="store.subject.sex"
      />
      <label class="btn btn-outline-primary" for="other">Divers</label>
    </div>

    <button
      type="submit"
      class="btn btn-proceed btn-lg w-100 mt-4 mb-3"
      :disabled="isFormInvalid"
    >
      Weiter
    </button>
  </form>
  <AgeChangeModal />
</template>

<script setup>
import AgeChangeModal from "@/components/utils/AgeChangeModal.vue";
import { Modal } from "bootstrap";
import { dhRegistrationStore } from "@/stores/dh-registration";
import { ref, computed, onMounted } from "vue";
import { samplePersons } from "@/assets/samplePersons";
const store = dhRegistrationStore();
const socialSecurityError = ref(0);
const firstNameError = ref(0);
const lastNameError = ref(0);

const isFormInvalid = computed(() => {
  return (
    store.subject.firstName === "" ||
    store.subject.lastName === "" ||
    store.subject.dateOfBirth === "" ||
    store.subject.socialSecurity === "" ||
    store.subject.sex === ""
  );
});

function validateSocialSecurity() {
  socialSecurityError.value = 1;

  var socialSecurityArray = store.subject.socialSecurity
    .toString(10)
    .split("")
    .map(Number);

  if (socialSecurityError.value === 1 && socialSecurityArray.length !== 10) {
    socialSecurityError.value = 2;
  }

  if (socialSecurityError.value === 1) {
    const dateParts = store.subject.dateOfBirth.split("-");
    let [year, month, day] = dateParts;
    year = year.slice(-2);
    const dobYear = parseInt(year, 10);
    const dobMonth = parseInt(month, 10);
    const dobDay = parseInt(day, 10);

    const enteredSocialSecurity = store.subject.socialSecurity.toString();

    const lastSixDigits = enteredSocialSecurity.slice(-6);

    const socialYear = parseInt(lastSixDigits.slice(4), 10);
    const socialMonth = parseInt(lastSixDigits.slice(2, 4), 10);
    const socialDay = parseInt(lastSixDigits.slice(0, 2), 10);

    if (
      dobYear !== socialYear ||
      dobMonth !== socialMonth ||
      dobDay !== socialDay
    ) {
      socialSecurityError.value = 3;
    }
  }

  if (socialSecurityError.value === 1) {
    var checkDigit = socialSecurityArray[3];
    var numbersToCheck = socialSecurityArray
      .slice(0, 3)
      .concat(socialSecurityArray.slice(4));
    var controlNumbers = [3, 7, 9, 5, 8, 4, 2, 1, 6];
    var checkSum = 0;
    for (let i = 0; i < numbersToCheck.length; i++) {
      checkSum += numbersToCheck[i] * controlNumbers[i];
    }
    checkSum %= 11;
    if (checkSum !== checkDigit) {
      socialSecurityError.value = 4;
    }
  }
}

function validateAge() {
  var dateOfBirth = new Date(store.subject.dateOfBirth);
  var ageDiff = Date.now() - dateOfBirth.getTime();
  var ageDate = new Date(ageDiff);
  store.temp.ageEntered = Math.abs(ageDate.getUTCFullYear() - 1970);
  if (Math.abs(store.temp.ageEntered - store.subject.age) > 1) {
    store.temp.ageValidationError = 1;
  } else {
    store.temp.ageValidationError = 2;
  }
}

function validateNames() {
  store.subject.firstName = store.subject.firstName.trim();
  const nameRegex =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\s,.'-]{2,}$/gimu;
  if (!nameRegex.test(store.subject.firstName)) {
    firstNameError.value = 1;
  } else {
    firstNameError.value = 2;
  }
  store.subject.lastName = store.subject.lastName.trim();
  nameRegex.lastIndex = 0;
  if (!nameRegex.test(store.subject.lastName)) {
    lastNameError.value = 1;
  } else {
    lastNameError.value = 2;
  }
}

function validateConsentData() {
  validateSocialSecurity();
  validateNames();
  validateAge();

  if (
    firstNameError.value === 2 &&
    lastNameError.value === 2 &&
    store.temp.ageValidationError === 2 &&
    socialSecurityError.value === 1
  ) {
    if (store.subject.age < 65) {
      store.abortAgeError = 1;
      store.step = 0;
    } else {
      store.step++;
    }
  } else if (store.temp.ageValidationError == 1) {
    const ageChangeModalEl = document.getElementById("ageChangeModal");
    const ageChange = new Modal(ageChangeModalEl);
    ageChange.show();
  }
}

function generateMockUp() {
  let randomPerson =
    samplePersons[Math.floor(Math.random() * samplePersons.length)];
  store.subject.firstName = randomPerson.firstName;
  store.subject.lastName = randomPerson.lastName;
  store.subject.dateOfBirth = randomPerson.dateOfBirth;
  store.subject.socialSecurity = randomPerson.socialSecurity;
  store.subject.sex = randomPerson.sex;
}

function emptyForm() {
  store.subject.firstName = "";
  store.subject.lastName = "";
  store.subject.dateOfBirth = "";
  store.subject.socialSecurity = "";
  store.subject.sex = "";
}

onMounted(() => {
  store.temp.ageValidationError = 0;
});
</script>

<style scoped>
.consent-text {
  font-size: 12px;
}
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
