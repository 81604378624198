<template>
  <div class="container-sm container-registration py-2 pt-0 px-0">
    <transition>
      <section
        class="px-4 mt-0"
        v-if="store.abortAgeError === 0 && store.abortEligibilityError === 0"
      >
        <TopBar v-if="store.step >= 1 && store.step !== 37" />
        <transition>
          <section v-if="store.step === -1"><LockScreen /><br /></section
        ></transition>

        <StartBlock />
        <EligibilityBlock />
        <ConsentBlock />
        <RiskBlock />
        <RandomBlock />
      </section>

      <section class="px-4 mt-5" v-else>
        <section v-if="store.abortAgeError !== 0">
          <AbortScreen
            :message="'Für Personen unter 65 Jahren ist eine regelmäßige Überprüfung des Herzrhythmus nicht notwendig. Danke für Ihr Interesse und bleiben Sie gesund!'"
          />
        </section>
        <section v-if="store.abortEligibilityError !== 0">
          <AbortScreen
            :message="'Wir bedanken uns für Ihre Zeit und Ihr Interesse.'"
          />
        </section>
      </section>
    </transition>
    <div class="text-center">
      <span class="demonstration-text ps-3 pe-0">
        [Test version, demo only!]</span
      >
    </div>
  </div>
</template>

<script setup>
import TopBar from "@/components/utils/TopBar.vue";
import StartBlock from "@/components/blocks/StartBlock.vue";
import EligibilityBlock from "@/components/blocks/EligibilityBlock.vue";
import ConsentBlock from "@/components/blocks/ConsentBlock.vue";
import RiskBlock from "@/components/blocks/RiskBlock.vue";
import RandomBlock from "@/components/blocks/RandomBlock.vue";
import AbortScreen from "@/components/screens/AbortScreen.vue";
import LockScreen from "@/components/screens/LockScreen.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style>
.container-registration {
  margin-bottom: 75px;
  max-width: 600px;
  h2,
  h5,
  h6 {
    text-align: center;
  }
  section {
    margin-top: 20px;
  }
  h2 {
    margin-bottom: 20px;
  }
  button.btn-proceed {
    background-color: #142b58;
    color: white;
  }

  .btn-group,
  .btn-group-vertical {
    display: flex;
  }

  .btn-group-question {
    margin: 20px 20px 0px 20px;
  }
  .btn-check + .btn {
    color: #142b58;
    border-color: #142b58;
    height: 60px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-check:unchecked + .btn {
    color: #142b58;
  }
  .btn-check:checked + .btn {
    color: white;
    background-color: #142b58;
  }
}

.popover {
  background-color: rgb(254, 255, 165);
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-bottom-color: rgb(254, 255, 165);
}

.v-enter-active {
  transition: all 0.6s ease;
}
.v-leave-active {
  display: none;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.demonstration-text {
  color: red;
  font-size: 10px;
}
</style>
